* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Set a minimum height for the entire page */
}

.footer {
    background-color: #74de51 !important;
    box-shadow: 0px -5px 5px -5px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-around;
    padding: 20px;
    margin-top: auto;
}

.footer-column {
    display: flex;
    flex-direction: column;
}

.logo-footer {
    width: 120px;
    height: auto;
}

.social-media-container {
    display: flex;
    justify-content: space-around;
}

.social-media-link {
    margin-right: 10px;
}

.social-media-icon {
    width: 30px;
    height: 30px;
    color: rgb(255, 255, 255);
}

.contact-text {
    margin-bottom: 10px;
    color: rgb(255, 255, 255);
}

a {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

@media (max-width: 440px) {
    .contact-text{
        font-size: 12px;
    }
    .social-media-icon {
        width: 20px;
        height: 20px;
    }
}
@media (max-width: 370px) {
    .contact-text{
        font-size: 10px;
    }
    .social-media-icon {
        width: 18px;
        height: 18px;
    }
}